<template>
    <div class="data-situation">
        <div class="head-box">
            <span>近30天总数据</span>
        </div>
        <div class="data-situation-header">
            <div class="situation-top-item">
                <span>直播总场次</span>
                <span>{{liveInfo.all_frequency?liveInfo.all_frequency:'0'}}</span>
            </div>
            <div class="situation-top-item">
                <span>直播总时长</span>
                <span>{{liveInfo.all_duration?liveInfo.all_duration:'0'}}</span>
            </div>
            <div class="situation-top-item">
                <span>观看总人数</span>
                <span>{{liveInfo.all_number_pviewers?liveInfo.all_number_pviewers:'0'}}</span>
            </div>
            <div class="situation-top-item">
                <span>新增粉丝数</span>
                <span>{{liveInfo.all_new_fans?liveInfo.all_new_fans:'0'}}</span>
            </div>
        </div>
        <div class="data-situation-basics">
            <div class="situation-basics-header">
                <div class="basics-title">
                    <div class="title-line"></div>
                    <span>基础数据</span>
                </div>
                <div class="basics-select-time">
                    <div class="basics-time-item" @click="toggleBasics(1)"
                         :class="{'time-item-active' : basicsRecently === 1}">7天
                    </div>
                    <div class="basics-time-item" @click="toggleBasics(2)"
                         :class="{'time-item-active' : basicsRecently === 2}">30天
                    </div>
                </div>
            </div>
            <div class="situation-basics-select">
                <el-select style="width: 170px;" v-model="basicsValue" placeholder="请选择" @change="selectBasicsChange">
                    <el-option
                            v-for="item in basicsList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div id="basicsData" style="width: 100%;height: 442px;position: relative"></div>
        </div>
        <div class="data-situation-view">
            <div class="situation-basics-header">
                <div class="basics-title">
                    <div class="title-line"></div>
                    <span>观看数据</span>
                </div>
                <div class="basics-select-time">
                    <div class="basics-time-item" @click="toggleView(1)"
                         :class="{'time-item-active' : viewRecently === 1}">7天
                    </div>
                    <div class="basics-time-item" @click="toggleView(2)"
                         :class="{'time-item-active' : viewRecently === 2}">30天
                    </div>
                </div>
            </div>
            <div class="situation-basics-select">
                <el-select style="width: 180px;" v-model="viewValue" placeholder="请选择" @change="selectViewChange">
                    <el-option
                            v-for="item in viewList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div id="viewData" style="width: 100%;height: 442px;position: relative"></div>
        </div>
        <div class="data-situation-interaction">
            <div class="situation-basics-header">
                <div class="basics-title">
                    <div class="title-line"></div>
                    <span>互动数据</span>
                </div>
                <div class="basics-select-time">
                    <div class="basics-time-item" @click="toggleInteraction(1)"
                         :class="{'time-item-active' : interactionRecently === 1}">7天
                    </div>
                    <div class="basics-time-item" @click="toggleInteraction(2)"
                         :class="{'time-item-active' : interactionRecently === 2}">30天
                    </div>
                </div>
            </div>
            <div class="situation-basics-select">
                <el-select style="width: 170px;" v-model="interactionValue" placeholder="请选择"
                           @change="selectInteractionChange">
                    <el-option
                            v-for="item in interactionList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div id="interactionData" style="width: 100%;height: 442px;position: relative"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LiveDataSituation",
        data() {
            return {
                liveInfo: {},
                basicsRecently: 1,
                //基础数据没有数据提示
                basicsDataNoPrompt: null,
                //直播场次
                live_frequency: [],
                //直播时长
                live_duration: [],
                //筛选基础数据
                basicsValue: 1,
                basicsList: [
                    {
                        value: 1,
                        label: '直播场次'
                    },
                    {
                        value: 2,
                        label: '直播时长（分钟）'
                    }
                ],
                // 观看数据
                viewRecently: 1,
                //观看数据没有数据提示
                viewDataNoPrompt: null,
                viewValue: 1,
                viewList: [
                    {
                        value: 1,
                        label: '观看人次'
                    },
                    {
                        value: 2,
                        label: '观看人数'
                    },
                    {
                        value: 3,
                        label: '观看人数峰值'
                    },
                    {
                        value: 4,
                        label: '人均观看时长（分钟）'
                    },
                ],
                live_number_viewers: [], //观看人次
                live_number_pviewers: [], //观看人数
                live_peak_number_viewers: [], //观看人数峰值
                live_avg_viewing_time: [], //人均观看时长
                // 互动数据
                interactionRecently: 1,
                interactionValue: 1,
                interactionList: [
                    {
                        value: 1,
                        label: '评论数'
                    },
                    {
                        value: 2,
                        label: '评论人数'
                    },
                    {
                        value: 3,
                        label: '新增粉丝'
                    },
                ],
                //观看数据没有数据提示
                interactionDataNoPrompt: null,
                live_comments: [], //评论数
                live_pcomments: [], //评论人数
                live_new_fans: [], //新增粉丝
            }
        },
        mounted() {
            this.basicsDataNoPrompt = document.createElement("div");
            this.viewDataNoPrompt = document.createElement("div");
            this.interactionDataNoPrompt = document.createElement("div");
            ;
            this.getData();
            window.onresize = () => {
                // 基础数据
                this.$echarts.init(document.getElementById('basicsData')).resize()
                // 观看数据
                this.$echarts.init(document.getElementById('viewData')).resize()
                // 互动数据
                this.$echarts.init(document.getElementById('interactionData')).resize()
            }
        },
        methods: {
            getData() {
                let param = {
                    id: this.$route.query.id,
                    platform_type: 1
                }
                this.$httpteacher.axiosGetBy(this.$api.tiktok_live_change, param, res => {
                    if (res.code == 200) {
                        this.liveInfo = res.data;
                        this.live_frequency = res.data.live_frequency;
                        this.live_duration = res.data.live_duration;
                        this.live_number_viewers = res.data.live_number_viewers;
                        this.live_number_pviewers = res.data.live_number_pviewers;
                        this.live_peak_number_viewers = res.data.live_peak_number_viewers;
                        this.live_avg_viewing_time = res.data.live_avg_viewing_time;
                        this.live_comments = res.data.live_comments;
                        this.live_pcomments = res.data.live_pcomments;
                        this.live_new_fans = res.data.live_new_fans;
                        this.basicsDataFun(this.live_frequency);
                        this.viewDataFun(this.live_number_viewers);
                        this.interactionDataFun(this.live_comments);
                    } else {
                        this.resetData();
                        this.$message.error(res.message)
                    }
                })
            },
            resetData() {
                this.liveInfo = {}
                this.live_frequency = [];
                this.live_duration = [];
                this.live_number_viewers = [];
                this.live_number_pviewers = [];
                this.live_peak_number_viewers = [];
                this.live_avg_viewing_time = [];
                this.live_comments = [];
                this.live_pcomments = [];
                this.live_new_fans = [];
                this.basicsDataFun(this.live_frequency);
                this.viewDataFun(this.live_number_viewers);
                this.interactionDataFun(this.live_comments);
            },
            // 选择直播场次 直播时长
            selectBasicsChange() {
                let arr = [];
                let num = this.basicsRecently === 1 ? -7 : -30;
                if (this.basicsValue === 1) {
                    arr = this.live_frequency.slice(num)
                } else if (this.basicsValue === 2) {
                    arr = this.live_duration.slice(num)
                } else {
                    this.basicsDataFun([])
                }
                this.basicsDataFun(arr)
            },
            // 选择观看数据
            selectViewChange() {
                let arr = [];
                let num = this.viewRecently === 1 ? -7 : -30;
                if (this.viewValue === 1) {
                    arr = this.live_number_viewers.slice(num)
                } else if (this.viewValue === 2) {
                    arr = this.live_number_pviewers.slice(num)
                } else if (this.viewValue === 3) {
                    arr = this.live_peak_number_viewers.slice(num)
                } else if (this.viewValue === 4) {
                    arr = this.live_avg_viewing_time.slice(num)
                } else {
                    this.viewDataFun([])
                }
                this.viewDataFun(arr)
            },
            // 选择互动数据
            selectInteractionChange() {
                let arr = [];
                let num = this.interactionRecently === 1 ? -7 : -30;
                if (this.interactionValue === 1) {
                    arr = this.live_comments.slice(num)
                } else if (this.interactionValue === 2) {
                    arr = this.live_pcomments.slice(num)
                } else if (this.interactionValue === 3) {
                    arr = this.live_new_fans.slice(num)
                } else {
                    this.interactionDataFun([])
                }
                this.interactionDataFun(arr)
            },
            //基础数据
            basicsDataFun(arr) {
                let basicsData = this.$echarts.init(document.getElementById('basicsData'));
                let time = [],
                    session = [];
                arr.forEach(item => {
                    time.push(item.date);
                    session.push(item.count)
                })
                let max = Math.max(...session);
                let basicsTitle = this.basicsValue === 1 ? '直播场次' : '直播时长（分钟）';
                basicsData.setOption({
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].axisValue;
                            let data = params[0].value;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + basicsTitle + '：</span> <sapn class="tooltip-title-right"> ' + data + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        },
                    },
                    color: '#584EEE',
                    legend: {},
                    //false用于多个grid对齐，true用于防止标签溢出
                    grid: {
                        containLabel: true,
                        x: 44,
                    },
                    xAxis: {
                        type: "category",
                        //从0开始
                        // boundaryGap:false,
                        data: time,
                        // 隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#999',
                                fontSize: 14
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#999"
                            }
                        }
                    },
                    yAxis: {
                        type: "value",
                        name: '场',
                        // min: min,
                        max: max,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#E6E6E6"]
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#999",
                                fontSize: 14
                            }
                        },
                        axisLabel: {
                            fontSize: "14",
                            marginTop: "35px",
                            formatter: function (value, index) {
                                if (value >= 10000) {
                                    return `${value / 10000}W`
                                } else {
                                    return value
                                }
                            },
                            show: true
                        }
                    },
                    series: [{
                        data: session,
                        type: "line",
                        lineStyle: {
                            width: 3,
                            shadowColor: "rgba(95,85,241,0.5)",
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 26,
                        },
                        smooth: true,
                        // symbol: "none",
                        showSymbol: false, //关闭线上默认的圆点
                        symbolSize: 5, //设置圆点的大小
                        itemStyle: {
                            emphasis: { // 鼠标经过时：
                                color: '#584EEE',
                                borderColor: '#ffffff',
                                borderWidth: 4,
                                shadowColor: 'rgba(133,127,221,1)',
                            },
                            normal: {
                                shadowBlur: 30,
                            }
                        },
                    }],
                }, true);
                if (session.length === 0) {
                    this.basicsDataNoPrompt.innerHTML = "暂无数据";
                    this.basicsDataNoPrompt.style.color = "#666";
                    this.basicsDataNoPrompt.style.position = "absolute";
                    this.basicsDataNoPrompt.style.top = "100px";
                    this.basicsDataNoPrompt.style.left = "50%";
                    document.querySelector("#basicsData").appendChild(this.basicsDataNoPrompt)
                }
            },
            //观看数据
            viewDataFun(arr) {
                let viewData = this.$echarts.init(document.getElementById('viewData'));
                let time = [],
                    session = [];
                arr.forEach(item => {
                    time.push(item.date);
                    session.push(item.count)
                })
                let max = Math.max(...session);
                let viewTitle = this.viewValue === 1 ? '观看人次' :
                    this.viewValue === 2 ? '观看人数' : this.viewValue === 3 ? '观看人数峰值' : '人均观看时长（分钟）';
                ;
                viewData.setOption({
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].axisValue;
                            let data = params[0].value;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + viewTitle + '：</span> <sapn class="tooltip-title-right"> ' + data + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        },
                    },
                    color: '#584EEE',
                    legend: {},
                    //false用于多个grid对齐，true用于防止标签溢出
                    grid: {
                        containLabel: true,
                        x: 44,
                    },
                    xAxis: {
                        type: "category",
                        //从0开始
                        // boundaryGap:false,
                        data: time,
                        // 隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#999',
                                fontSize: 14
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#999"
                            }
                        }
                    },
                    yAxis: {
                        type: "value",
                        name: '场',
                        // min: min,
                        max: max,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#E6E6E6"]
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#999",
                                fontSize: 14
                            }
                        },
                        axisLabel: {
                            fontSize: "14",
                            marginTop: "35px",
                            formatter: function (value, index) {
                                if (value >= 10000) {
                                    return `${value / 10000}W`
                                } else {
                                    return value
                                }
                            },
                            show: true
                        }
                    },
                    series: [{
                        data: session,
                        type: "line",
                        lineStyle: {
                            width: 3,
                            shadowColor: "rgba(95,85,241,0.5)",
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 26,
                        },
                        smooth: true,
                        // symbol: "none",
                        showSymbol: false, //关闭线上默认的圆点
                        symbolSize: 5, //设置圆点的大小
                        itemStyle: {
                            emphasis: { // 鼠标经过时：
                                color: '#584EEE',
                                borderColor: '#ffffff',
                                borderWidth: 4,
                                shadowColor: 'rgba(133,127,221,1)',
                            },
                            normal: {
                                shadowBlur: 30,
                            }
                        },
                    }],
                }, true);
                if (session.length === 0) {
                    this.viewDataNoPrompt.innerHTML = "暂无数据";
                    this.viewDataNoPrompt.style.color = "#666";
                    this.viewDataNoPrompt.style.position = "absolute";
                    this.viewDataNoPrompt.style.top = "100px";
                    this.viewDataNoPrompt.style.left = "50%";
                    document.querySelector("#viewData").appendChild(this.viewDataNoPrompt)
                }
            },
            //互动数据
            interactionDataFun(arr) {
                let interactionData = this.$echarts.init(document.getElementById('interactionData'));
                let time = [],
                    session = [];
                arr.forEach(item => {
                    time.push(item.date);
                    session.push(item.count)
                })
                let max = Math.max(...session);
                let interactionTitle = this.interactionValue === 1 ? '评论数' : this.interactionValue === 2 ? '评论人数' : '新增粉丝';
                interactionData.setOption({
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].axisValue;
                            let data = params[0].value;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + interactionTitle + '：</span> <sapn class="tooltip-title-right"> ' + data + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        },
                    },
                    color: '#584EEE',
                    legend: {},
                    //false用于多个grid对齐，true用于防止标签溢出
                    grid: {
                        containLabel: true,
                        x: 44,
                    },
                    xAxis: {
                        type: "category",
                        //从0开始
                        // boundaryGap:false,
                        data: time,
                        // 隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#999',
                                fontSize: 14
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#999"
                            }
                        }
                    },
                    yAxis: {
                        type: "value",
                        name: '场',
                        // min: min,
                        max: max,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#E6E6E6"]
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#999",
                                fontSize: 14
                            }
                        },
                        axisLabel: {
                            fontSize: "14",
                            marginTop: "35px",
                            formatter: function (value, index) {
                                if (value >= 10000) {
                                    return `${value / 10000}W`
                                } else {
                                    return value
                                }
                            },
                            show: true
                        }
                    },
                    series: [{
                        data: session,
                        type: "line",
                        lineStyle: {
                            width: 3,
                            shadowColor: "rgba(95,85,241,0.5)",
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 26,
                        },
                        smooth: true,
                        // symbol: "none",
                        showSymbol: false, //关闭线上默认的圆点
                        symbolSize: 5, //设置圆点的大小
                        itemStyle: {
                            emphasis: { // 鼠标经过时：
                                color: '#584EEE',
                                borderColor: '#ffffff',
                                borderWidth: 4,
                                shadowColor: 'rgba(133,127,221,1)',
                            },
                            normal: {
                                shadowBlur: 30,
                            }
                        },
                    }],
                }, true);
                if (session.length === 0) {
                    this.interactionDataNoPrompt.innerHTML = "暂无数据";
                    this.interactionDataNoPrompt.style.color = "#666";
                    this.interactionDataNoPrompt.style.position = "absolute";
                    this.interactionDataNoPrompt.style.top = "100px";
                    this.interactionDataNoPrompt.style.left = "50%";
                    document.querySelector("#interactionData").appendChild(this.interactionDataNoPrompt)
                }
            },
            toggleBasics(num) {
                this.basicsRecently = num;
                this.selectBasicsChange();
            },
            toggleView(num) {
                this.viewRecently = num;
                this.selectViewChange();
            },
            toggleInteraction(num) {
                this.interactionRecently = num;
                this.selectInteractionChange();
            }
        }
    }
</script>

<style scoped lang="scss">
    .data-situation {
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep.tooltip {
            height: 70px;
            border-radius: 2px;
            box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
            background: #fff;

            .tooltip-box {
                padding: 13px 20px 0;
            }

            .tooltip-title {
                font-size: 12px;
                color: #999999;
            }

            .tooltip-title-right {
                float: right;
                padding-right: 10px;
                font-size: 16px;
                color: #5F55F1;
            }

            .tooltip-title-left {
                color: #333;
            }
        }

        .head-box {
            padding: 16px 0 0 52px;

            span {
                font-size: 14px;
                color: #666666;
                font-weight: 400;
            }
        }

        .data-situation-header {
            display: flex;
            justify-content: space-between;
            padding: 34px 132px 82px 52px;

            .situation-top-item {
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                    line-height: 1;

                    &:nth-child(1) {
                        font-size: 14px;
                        color: #666666;
                        font-weight: 400;
                    }

                    &:nth-child(2) {
                        color: #584EEE;
                        font-size: 24px;
                        font-weight: bold;
                        margin-top: 20px;
                    }
                }
            }
        }

        .data-situation-basics, .data-situation-view, .data-situation-interaction {
            padding: 0 50px 0 20px;

            &.data-situation-interaction {
                padding-bottom: 40px;
            }

            .situation-basics-header {
                display: flex;
                line-height: 1;
                justify-content: space-between;

                .basics-title {
                    display: flex;
                    align-items: center;

                    .title-line {
                        width: 5px;
                        height: 20px;
                        background: #584EEE;
                        border-radius: 3px;
                        margin-right: 10px;
                    }

                    span {
                        font-size: 16px;
                        color: #333;
                    }
                }

                .basics-select-time {
                    display: flex;

                    .basics-time-item {
                        background: #F1F0FE;
                        font-size: 12px;
                        color: #666666;
                        line-height: 30px;
                        text-align: center;
                        width: 50px;
                        height: 30px;
                        cursor: pointer;

                        &:nth-child(1) {
                            border-radius: 5px 0px 0px 5px;
                        }

                        &:nth-child(2) {
                            border-radius: 0px 5px 5px 0px;
                        }
                    }

                    .time-item-active {
                        background: #564CED;
                        color: #fff;
                    }
                }
            }

            .situation-basics-select {
                margin-top: 22px;
                padding-left: 14px;
            }
        }
    }
</style>